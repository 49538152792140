<template lang="pug">
  div.row.justify-content-center
    div.col-auto.mb-3
      UserCountCard
    div.col-auto.mb-3
      SalesCard
    div.col-auto.mb-3
      SalesCard2
    //- div.col-auto.mb-3
      MonitorCountCard
    div.col-auto.mb-3
      MonitorV2TotalCountCard
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="0")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="1")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="2")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="3")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="4")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="5")
</template>

<script>
// @ is an alias to /src
import UserCountCard from '@/components/cards/UserCountCard.vue';
import SalesCard from '@/components/cards/SalesCard.vue';
import SalesCard2 from '@/components/cards/SalesCard2.vue';
import MonitorCountCard from '@/components/cards/MonitorCountCard.vue';
import MonitorV2TotalCountCard from '@/components/cards/MonitorV2TotalCountCard.vue';
import MonitorV2CountCard from '@/components/cards/MonitorV2CountCard.vue';

export default {
  name: 'Dash',
  components: {
    UserCountCard, SalesCard, SalesCard2, MonitorCountCard, MonitorV2CountCard, MonitorV2TotalCountCard
  },
  created (){
    this.$store.commit("monitor_v2_count_reset", []);
  },
}
</script>
